<template>
  <v-dialog v-model="openModal" persistent max-width="80%">
    <v-card>
      <v-card-title>
        {{ modalTitle }}
        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal()">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="listItems"
      :loading="loading"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left class="justify-center">
          <template v-slot:activator="{ on }">
            <v-icon @click="interactionList(item.id)" v-on="on">
              mdi-tooltip-text-outline
            </v-icon>
          </template>
          <span>Interações</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status.name="{ item }">
        <v-chip :color="getColor(item.status.name)" class="d-flex justify-center">
          {{ item.status.display_name }}
        </v-chip>
      </template>
    </v-data-table>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modalTitle: {
      type: String,
      default: "Solicitações",
    },
    listItems: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      openModal: true,
      loading: true,
      headers: [
        {
          "text": "ID",
          "value": "friendly_id",
          "align": "center"
        },
        {
          "text": "Empresa",
          "value": "company.name",
          "align": "center"
        },
        {
          "text": "Solicitante",
          "value": "requester.nome",
          "align": "center"
        },
        {
          "text": "Data de Abertura",
          "value": "created_at_format",
          "align": "center"
        },
        {
          "text": "Finalizar até",
          "value": "sla_deadline_finish_format",
          "align": "center"
        },
        {
          "text": "Última atualização",
          "value": "updated_at_format",
          "align": "center"
        },
        {
          "text": "Tipo Solicitação",
          "value": "description",
          "align": "center"
        },
        {
          "text": "Status",
          "value": "status.name",
          "align": "center"
        },
        {
          "text": "",
          "value": "actions",
        }
      ],
    }
  },

  mounted() {
    this.loading = false;
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
    interactionList(solicitation_id) {
      const route = this.$router.resolve({
        name: "interactions",
        params: { id: solicitation_id }
      }).href;

      window.open(route, "_blank");
    },
    getColor(status) {
      switch (status) {
        case "aguarda_aprovacao":
          return "#9ae6b4";
        case "aguarda_validacao":
          return "#f6ad55";
        case "em_atendimento":
          return "#b794f4";
        case "envio_fornecedor":
          return "#9decf9";
        case "resposta_fornecedor":
          return "#329cc7";
        case "Cancelar":
          return "#b7c4c7";
        case "validada":
          return "#68d391";
        case "Finalizar":
          return "#acafac";
        case "em_processamento":
          return "#f1d98b";
        case "finalizar_erro":
          return "#e0a3a3";
        case "aprovada":
          return "#80a7ec";
        default:
          return "white";
      }
    },
  }
}

</script>
<style scoped>

</style>