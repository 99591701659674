<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined class="mx-auto" elevation="2">
          <div class="d-flex justify-end pr-4 pt-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary"
                  style="cursor: pointer;"
                >
                  mdi-information
                </v-icon>
              </template>

              <span>
                Esta consulta recupera o número de Solicitações abertas por Solicitante no período de ({{day}}).
              </span>
            </v-tooltip>
            <v-icon v-if="showMdiWindowMax" @click="changeCols('12'); showMdiWindowMax = !showMdiWindowMax" class="ml-2">mdi-window-maximize</v-icon>
            <v-icon v-else @click="changeCols('6'); showMdiWindowMax = !showMdiWindowMax" class="ml-2">mdi-window-restore</v-icon>
          </div>
          <v-card-title class="text-h6 font-weight-bold justify-center">
            Total Solicitações por Solicitante
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="listItems"
            :items-per-page="5"
            :loading="loading"
            @click:row="openModal"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <SolicitationListModal
      v-if="showModal"
      :modalTitle="titleModal"
      :listItems="listModal"
      @close="showModal = false"
    ></SolicitationListModal>
  </v-container>
</template>
<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions, mapState } from "vuex";
import SolicitationListModal from "@/components/solicitation/modal/SolicitationListModal.vue";

export default {
  components: { SolicitationListModal },
  props: {
    selectedDate: {
      type: Object,
    },
    day: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: true,
      messageError: '',
      showErrorMessage: false,
      showModal: false,
      listModal: [],
      titleModal: '',
      listItems: [],
      showMdiWindowMax: true,
      headers: [
        {
          "text": "Solicitante",
          "value": "requester_name",
          "align": "center"
        },
        {
          "text": "Total",
          "value": "total",
          "align": "center"
        },
      ],
    };
  },
  async mounted() {
    await this.loadDataComponent();
  },
  computed: {
    ...mapState("dashboard", ["totalSolicitationRequester"])
  },
  methods: {
    ...mapActions("dashboard", ['loadTotalSolicitationRequester']),

    changeCols(val) {
      this.$emit('changeCols', val);
    },
    async loadDataComponent() {
      try {
        this.loading = true;
        if (this.selectedDate.date !== '' || this.selectedDate?.customDate?.start !== null || this.selectedDate?.value !== '') {
          await this.loadTotalSolicitationRequester(this.selectedDate);
          this.listItems = this.totalSolicitationRequester.data;
          this.day = this.totalSolicitationRequester.interval;
        }
      } catch (error) {
        this.showErrorMessage = true;
        this.messageError = error.message;
        await this.$swal("Oops...", error.message, "error");
      }
      this.loading = false;
    },

    openModal(event) {
      this.listModal = [];
      if (event.solicitations) {
        this.listModal = event.solicitations;
        this.titleModal = 'Solicitações Solicitante: ' + event.requester_name;
        this.showModal = true;
      }
    },

  },
  watch: {
    async selectedDate(newDate) {
      await this.loadDataComponent(newDate);
    }
  },
};
</script>
<style scoped>
</style>
