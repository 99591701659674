<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined class="mx-auto" elevation="2">
          <div class="d-flex justify-end pr-4 pt-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary"
                  style="cursor: pointer;"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>
                Este gráfico compara solicitações concluídas dentro e fora do SLA no período de ({{ day }}).
              </span>
            </v-tooltip>
            <v-icon @click="downloadPNG" class="ml-2" style="color: #4d638c;">mdi-file-png-box</v-icon>
            <v-icon v-if="showMdiWindowMax" @click="changeCols('12'); showMdiWindowMax = !showMdiWindowMax" class="ml-2">mdi-window-maximize</v-icon>
            <v-icon v-else @click="changeCols('6'); showMdiWindowMax = !showMdiWindowMax" class="ml-2">mdi-window-restore</v-icon>
          </div>
          <v-col v-if="loading" class="col-md-12 d-flex justify-center align-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
          <v-col v-if="showErrorMessage" class="col-md-12 d-flex justify-center align-center">
            {{ messageError }}
          </v-col>
          <div v-show="!loading" style="position: relative; height: 100%; width: 100%">
            <canvas ref="pieChart"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <SolicitationListModal
      v-if="showModal"
      :modalTitle="titleModal"
      :listItems="listModal"
      @close="showModal = false"
    ></SolicitationListModal>

  </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions, mapState } from "vuex";
import SolicitationListModal from "@/components/solicitation/modal/SolicitationListModal.vue";

export default {
  components: { SolicitationListModal },
  props: {
    selectedDate: {
      type: Object,
    },
    day: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: true,
      messageError: "",
      chartInstance: null,
      showErrorMessage: false,
      titleModal: "",
      showModal: false,
      listModal: [],
      showMdiWindowMax: true,
    };
  },
  mounted() {},
  computed: {
    ...mapState("dashboard", ["totalFinishSla"])
  },
  methods: {
    ...mapActions("dashboard", ["loadTotalFinishSla"]),

    downloadPNG() {
      const link = document.createElement("a");
      link.href = this.chartInstance.toBase64Image();
      link.download = "TotalFinishSla.png";
      link.click();
    },

    changeCols(val) {
      this.$emit('changeCols', val);
    },
    async loadDataComponent() {
      try {
        this.loading = true;
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }
        await this.loadTotalFinishSla(this.selectedDate);
        this.day = this.totalFinishSla.interval;
        this.createPieChart(this.totalFinishSla.data);
      } catch (error) {
        this.loading = false;
        this.showErrorMessage = true;
        this.messageError = error.message;
      }
    },

    onChartClick(event) {
      const activePoints = this.chartInstance.getElementsAtEventForMode(event, "nearest", { intersect: true }, false);
      if (activePoints.length > 0) {
        const chartIndex = activePoints[0].index;
        const label = this.chartInstance.data.labels[chartIndex];

        if (label === 'Dentro') {
          this.titleModal = 'Solicitações Finalizadas Dentro do SLA';
          this.listModal = this.totalFinishSla.solicitations.in;
        } else if (label === 'Fora') {
          this.titleModal = 'Solicitações Finalizadas Fora do SLA';
          this.listModal = this.totalFinishSla.solicitations.out;
        }
        this.showModal = true;
      }
    },

    createPieChart(data) {
      const labels = Object.keys(data);
      const values = Object.values(data);

      const ctx = this.$refs.pieChart;

      this.chartInstance = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: ['rgba(99,255,102,0.5)', 'rgba(255, 99, 132, 0.5)'],
              borderColor: ['rgba(99,255,102,0.5)', 'rgba(255, 99, 132, 0.5)'],
              borderWidth: 1,
              hoverOffset: 20
            },
          ],
        },
        options: {
          onClick: this.onChartClick,
          plugins: {
            title: {
              display: true,
              text: [
                'Solicitações Finalizadas Dentro vs Fora do SLA - ( ' + this.day + ')',
              ],
              color: 'black',
              font: {
                size: 20,
                weight: 'bold',
              },
              align: 'center',
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              formatter: (value, context) => {
                return value;
              },
              font: {
                size: 15,
                weight: 'bold'
              },
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const label = tooltipItem.label || "";
                  const value = tooltipItem.raw || 0;
                  return `${label}: ${value}`;
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,

        },
        plugins: [ChartDataLabels],
      });
      this.loading = false;
    },
  },
  watch: {
    async selectedDate(newDate) {
      await this.loadDataComponent(newDate);
    }
  },
};
</script>

<style scoped>
</style>
