<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined class="mx-auto">
          <v-col cols="12">
            <v-card-title>
              <h3><v-icon class="mr-1" color="primary">mdi-chart-box</v-icon>Dashboard</h3>
            </v-card-title>
          </v-col>
          <v-divider></v-divider>
          <v-row>
            <v-container>
              <v-col cols="2" class="d-flex justify-center">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <v-date-picker
                    v-if="customDateRange"
                    v-model="customDate"
                    range
                    @input="applyCustomDate"
                    :first-day-of-week="1"
                  ></v-date-picker>
                  <v-divider></v-divider>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formattedDate"
                      label="Selecione a data"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-list>
                    <v-list-item v-for="(option, index) in options" :key="index" @click="selectOption(option)">
                      <v-list-item-title>{{ option.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-container>
          </v-row>
          <v-row>
            <v-col cols="3">
              <TotalCreated
                :selectedDate="selectedDate"
              ></TotalCreated>
            </v-col>
            <v-col cols="3">
              <TotalInProgress
                :selectedDate="selectedDate"
              ></TotalInProgress>
            </v-col>
            <v-col cols="3">
              <TotalExpireSlaFinish
                :selectedDate="selectedDate"
              ></TotalExpireSlaFinish>
            </v-col>
            <v-col cols="3">
              <TotalFinish
                :selectedDate="selectedDate"
              ></TotalFinish>
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col :cols="colGraphWeekOpenCLosed">
              <GraphWeekOpenClosed
                :selectedDate="selectedDate"
                @changeCols="colGraphWeekOpenCLosed = $event"
              ></GraphWeekOpenClosed>
            </v-col>
            <v-col :cols="colGraphSolicitationTypeOpenClosed">
              <SolicitationTypeOpenClosed
                :selectedDate="selectedDate"
                @changeCols="colGraphSolicitationTypeOpenClosed = $event"
              ></SolicitationTypeOpenClosed>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="colGraphTotalStatus">
              <TotalStatus
                :selectedDate="selectedDate"
                @changeCols="colGraphTotalStatus = $event"
              ></TotalStatus>
            </v-col>
            <v-col :cols="colGraphTotalFinishSla">
              <TotalFinishSla
                :selectedDate="selectedDate"
                @changeCols="colGraphTotalFinishSla = $event"
              ></TotalFinishSla>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="colGraphAverageType">
              <AverageType
                :selectedDate="selectedDate"
                @changeCols="colGraphAverageType = $event"
              ></AverageType>
            </v-col>
            <v-col :cols="colGraphTotalSolicitationRequester">
              <TotalSolicitationRequester
                :selectedDate="selectedDate"
                @changeCols="colGraphTotalSolicitationRequester = $event"
              ></TotalSolicitationRequester>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GraphWeekOpenClosed from "@/components/dashboard/GraphWeekOpenClosed.vue";
import SolicitationTypeOpenClosed from "@/components/dashboard/SolicitationTypeOpenClosed.vue";
import TotalStatus from "@/components/dashboard/TotalStatus.vue";
import AverageType from "@/components/dashboard/AverageType.vue";
import TotalFinishSla from "@/components/dashboard/TotalFinishSla.vue";
import TotalCreated from "@/components/dashboard/TotalCreated.vue";
import TotalInProgress from "@/components/dashboard/TotalInProgress.vue";
import TotalExpireSlaFinish from "@/components/dashboard/TotalExpireSlaFinish.vue";
import TotalFinish from "@/components/dashboard/TotalFinish.vue";
import TotalSolicitationRequester from "@/components/dashboard/TotalSolicitationRequester.vue";

export default {
  components: { TotalSolicitationRequester, TotalFinish, TotalExpireSlaFinish, TotalInProgress, TotalCreated, TotalStatus, SolicitationTypeOpenClosed, GraphWeekOpenClosed, AverageType, TotalFinishSla },
  data() {
    return {
      loading: true,
      colGraphWeekOpenCLosed: '6',
      colGraphSolicitationTypeOpenClosed: '6',
      colGraphTotalFinishSla: '6',
      colGraphTotalStatus: '6',
      colGraphAverageType: '6',
      colGraphTotalSolicitationRequester: '6',
      selectedDate: {
        date: '',
        label: '',
        value: '',
        customDate: { start: null, end: null },
      },
      customDate: [],
      menu: false,
      customDateRange: false,
      options: [
        { label: 'Definir Personalizada', value: 'custom' },
        { label: 'Hoje', value: 'today' },
        { label: 'Ontem', value: 'yesterday' },
        { label: 'Essa semana', value: 'thisWeek' },
        { label: 'Últimos 7 dias', value: 'last7Days' },
        { label: 'Últimos 30 dias', value: 'last30Days' },
        { label: 'Esse mês', value: 'thisMonth' },
        { label: 'Últimos 3 meses', value: 'last3Months' },
        { label: 'Esse trimestre', value: 'thisQuarter' },
        { label: 'Últimos 90 dias', value: 'last90Days' },
        { label: 'Últimos 6 meses', value: 'last6Months' },
        { label: 'Este ano', value: 'thisYear' },
        { label: 'Últimos 365 dias', value: 'last365Days' },
      ],
    };
  },
  computed: {
    formattedDate() {
      if (this.customDateRange) {
        if (this.customDate.length === 2) {
          const [start, end] = this.customDate;
          return `${this.formatDateStringBR(start)} - ${this.formatDateStringBR(end)}`;
        }
        return "Selecionar intervalo";
      }
      if (this.selectedDate.date === "" && this.selectedDate.label !== "") {
        return this.selectedDate.label;
      }
      return this.formatDate({ date: new Date() });
    },
  },
  methods: {
    formatDateStringBR(dateStr) {
      const [year, month, day] = dateStr.split('-');
      return `${day}/${month}/${year}`;
    },
    formatDate(date) {
      const d = new Date(date.date);
      const day = d.getDate().toString().padStart(2, '0');
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    selectOption(option) {
      this.customDateRange = false;
      if (option.value === 'custom') {
        this.customDateRange = true;
      }
      this.selectedDate = {
        date: '',
        label: option.label,
        value: option.value,
        customDate: { start: null, end: null },
      }
      if (!this.customDateRange) {
        this.menu = false;
      }
    },
    applyCustomDate() {
      if (this.customDate.length === 2) {
        this.selectedDate = {
          customDate: { start: this.customDate[0], end: this.customDate[1] },
          date: '',
          label: '',
          value: '',
      };
        this.menu = false;
      }
    },
  },
  mounted() {
    const d = new Date();
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    this.selectedDate = {
      date: `${year}-${month}-${day}`,
      label: '',
      value: '',
      customDate: { start: null, end: null },
    };
  },
};
</script>
<style scoped></style>
