<template>
  <v-container>
    <v-col cols="12" class="pt-0">
      <v-card class="pa-4 text-center">
        <div class="d-flex justify-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-2"
                color="primary"
                style="cursor: pointer;"
              >
                mdi-information
              </v-icon>
            </template>

            <span>
                Esta consulta recupera o número de solicitações finalizadas no período de ({{day}}).
              </span>
          </v-tooltip>
        </div>
        <v-card-text @click="openModal" style="cursor: pointer">
          <p class="text-subtitle-1 font-weight-medium"> Finalizado </p>
          <v-col v-if="loading" class="col-md-12 d-flex justify-center align-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
          <p v-else class="text-h4 font-weight-bold"> {{ total }} </p>
        </v-card-text>
      </v-card>
    </v-col>
    <SolicitationListModal
      v-if="showModal"
      :modalTitle="titleModal"
      :listItems="listModal"
      @close="showModal = false"
    ></SolicitationListModal>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SolicitationListModal from "@/components/solicitation/modal/SolicitationListModal.vue";

export default {
  components: { SolicitationListModal },
  props: {
    selectedDate: {
      type: Object,
      default: () => {
        return {
          date: '',
          label: '',
          value: '',
          customDate: { start: null, end: null },
        };
      },
    },
    day: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: true,
      total: 0,
      messageError: '',
      showErrorMessage: false,
      showModal: false,
      listModal: [],
      titleModal: '',
    };
  },
  async mounted() {
    await this.loadDataComponent();
  },
  computed: {
    ...mapState("dashboard", ["totalFinish"])
  },
  methods: {
    ...mapActions("dashboard", ['loadTotalFinish']),

    async loadDataComponent() {
      try {
        this.loading = true;
        if (this.selectedDate.date !== '' || this.selectedDate?.customDate?.start !== null || this.selectedDate?.value !== '') {
          await this.loadTotalFinish(this.selectedDate);
          this.total = this.totalFinish.total;
          this.day = this.totalFinish.interval;
        }
      } catch (error) {
        this.showErrorMessage = true;
        this.messageError = error.message;
        this.$swal("Oops...", error.message, "error");
      }
      this.loading = false;
    },

    openModal() {
      this.listModal = [];
      this.titleModal = 'Solicitações Finalizadas - ' + this.day;
      if (this.totalFinish.solicitations) {
        this.listModal = this.totalFinish.solicitations;
        this.showModal = true;
      }
    },
  },
  watch: {
    async selectedDate(newDate) {
      await this.loadDataComponent(newDate);
    }
  },
};
</script>
<style scoped>
</style>
